<template>
    <div class="w-100 mt-5 px-3">
      <h3 class="mb-4">Script Generator</h3>
      <form @submit.prevent="generateScript">
        <div class="mb-3">
          <label for="templateInput" class="form-label">Template String</label>
          <textarea
            id="templateInput"
            v-model="template"
            class="form-control"
            rows="5"
            style="white-space: nowrap; overflow-x: auto;"
            placeholder="Enter your template string here..."
          ></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Generate Script</button>
      </form>
    </div>
  </template>
  
  <script>
  import { axiosRequest } from "@/utils/axios";
  import { useToast } from "vue-toastification";
  
  const toast = useToast();
  
  export default {
    data() {
      return {
        template: "", // Input string for the template
      };
    },
    methods: {
      async generateScript() {
        if (!this.template.trim()) {
          toast.error("Template string cannot be empty.");
          return;
        }
  
        try {
          const response = await axiosRequest(
            "post",
            "script/generate-script",
            { template: this.template },
            { responseType: "blob" } // Ensure response type is a blob for file data
          );
  
          // Create a blob for the response data
          const blob = new Blob([response.data], { type: "text/plain" });
          const downloadUrl = URL.createObjectURL(blob);
  
          // Create a temporary anchor element to trigger the download
          const link = document.createElement("a");
          link.href = downloadUrl;
  
          // Set the download attribute with a default filename
          const contentDisposition = response.headers["content-disposition"];
          const filename = contentDisposition
            ? contentDisposition.split("filename=")[1]?.trim()
            : "generated_script.txt";
          link.download = filename;
  
          // Append the link, trigger click, and remove the link
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          toast.success("Script generated and downloaded successfully.");
        } catch (error) {
          console.error("Error generating script:", error);
          toast.error("Failed to generate the script.");
        }
      },
    },
  };
  </script>
  
  <style>
  textarea {
    resize: none;
  }
  </style>
  