<template>
    <div class="w-100 mt-5 px-3">
      <h3 class="mb-4">Interfaces</h3>
      <div class="table-responsive">
        <table class="table table-striped table-hover align-middle">
          <thead class="table-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>IP</th>
              <th>Next Hop</th>
              <th>Usage</th>
              <th>Max Usage</th>
              <th>Ports</th>
              <th>Priority</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bgpInterface in sortedInterfaces" :key="bgpInterface.id">
              <td>{{ bgpInterface.id }}</td>
              <td>{{ bgpInterface.Name }}</td>
              <td>{{ bgpInterface.Ip }}</td>
              <td>{{ bgpInterface.NextHop }}</td>
              <td>{{ bgpInterface.Usage }}</td>
              <td>{{ bgpInterface.MaxUsage }}</td>
              <td>{{ bgpInterface.Ports.join(', ') }}</td>
              <td>{{ bgpInterface.Priority }}</td>
              <td>
                <button
                  class="btn btn-sm"
                  :class="bgpInterface.Active ? 'btn-success' : 'btn-danger'"
                  @click="toggleActive(bgpInterface.id, !bgpInterface.Active)"
                >
                  {{ bgpInterface.Active ? 'Active' : 'Disabled' }}
                </button>
              </td>
              <td>
                <button
                  class="btn btn-primary btn-sm"
                  @click="openPopup(bgpInterface)"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Popup Modal -->
      <div v-if="selectedInterface" class="modal-backdrop">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Interface Details (ID: {{ selectedInterface.id }})</h5>
            <button type="button" class="btn-close" @click="closePopup"></button>
          </div>
          <div class="modal-body">
            <p><strong>Name:</strong> {{ selectedInterface.Name }}</p>
            <p><strong>IP:</strong> {{ selectedInterface.Ip }}</p>
            <p><strong>Next Hop:</strong> {{ selectedInterface.NextHop }}</p>
            <p><strong>Usage:</strong> {{ selectedInterface.Usage }}</p>
            <p><strong>Max Usage:</strong> {{ selectedInterface.MaxUsage }}</p>
            <p><strong>Ports:</strong> {{ selectedInterface.Ports.join(', ') }}</p>
            <p><strong>Priority:</strong> {{ selectedInterface.Priority }}</p>
            <button
              class="btn btn-sm mt-3"
              :class="selectedInterface.Active ? 'btn-success' : 'btn-danger'"
              @click="toggleActive(selectedInterface.id, !selectedInterface.Active)"
            >
              {{ selectedInterface.Active ? 'Active' : 'Disabled' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useRoute, useRouter } from "vue-router";
  import { axiosRequest } from "@/utils/axios";
  import { useToast } from "vue-toastification";
  
  const toast = useToast();
  
  export default {
    data() {
      return {
        interfaces: [],
        selectedInterface: null,
      };
    },
    computed: {
      // Sort interfaces by ID
      sortedInterfaces() {
        return this.interfaces.sort((a, b) => a.id - b.id);
      },
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      return { route, router };
    },
    mounted() {
      this.fetchInterfaces();
  
      // Check if a specific interface ID is in the URL
      const interfaceId = this.route.params.id;
      if (interfaceId) {
        this.fetchInterfaceDetails(Number(interfaceId));
      }
    },
    methods: {
      async fetchInterfaces() {
        try {
          const response = await axiosRequest("get", "interface");
          this.interfaces = response.data;
        } catch (error) {
          console.error("Error fetching interfaces:", error);
          toast.error("Failed to fetch interfaces.");
        }
      },
      openPopup(bgpInterface) {
        if (!bgpInterface) {
          toast.error("Invalid interface selected.");
          return;
        }
  
        this.selectedInterface = bgpInterface;
  
        // Update the URL with the selected interface ID
        this.router.push({ name: "Interfaces", params: { id: bgpInterface.id } });
      },
      closePopup() {
        this.selectedInterface = null;
  
        // Remove the interface ID from the URL
        this.router.push({ name: "Interfaces" });
      },
      async toggleActive(id, isActive) {
        try {
          await axiosRequest("put", `interface/${id}`, { active: isActive });
          toast.success(`Interface ${id} is now ${isActive ? "Active" : "Disabled"}.`);
  
          // Update the active state in the table
          const updatedInterface = this.interfaces.find((iface) => iface.id === id);
          if (updatedInterface) {
            updatedInterface.Active = isActive;
          }
  
          // Update the active state in the modal if it's open
          if (this.selectedInterface?.id === id) {
            this.selectedInterface.Active = isActive;
          }
        } catch (error) {
          console.error("Error toggling active state:", error);
          toast.error("Failed to update the interface status.");
        }
      },
      async fetchInterfaceDetails(id) {
        try {
          const response = await axiosRequest("get", `interface/${id}`);
          this.selectedInterface = response.data;
        } catch (error) {
          console.error(`Error fetching details for interface ${id}:`, error);
          toast.error("Failed to load interface details.");
        }
      },
    },
  };
  </script>
  
  <style>
  .table {
    width: 100%;
    margin-top: 20px;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .modal-body {
    margin-top: 20px;
  }
  </style>
  