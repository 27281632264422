<template>
  <div class="w-100 center mt-5 p-3">
    <div class="card mb-3 pb-3" v-if="loaded">
      <form @submit.prevent="saveSettings">
        <!-- Main Interface Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">Main Interface</h5>
          </div>
          <div class="card-body">
            <label for="mainIface" class="form-label">Main Interface</label>
            <select v-model="settings.mainIface" id="mainIface" class="form-control" >
              <option value="" disabled>Select an interface</option>
              <option v-for="iface in options" :key="iface.name" :value="iface.name">
                {{ iface.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Debug Mode Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">Debug Mode</h5>
          </div>
          <div class="card-body">
            <label for="debugMode" class="form-label">Enable Debug Mode</label>
            <input type="checkbox" v-model="settings.debugMode" id="debugMode" class="form-check-input" />
          </div>
        </div>

        <!-- Database Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">Database Settings</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="dbHost" class="form-label">Host</label>
                <input type="text" v-model="settings.databaseSetting.host" id="dbHost" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="dbName" class="form-label">Database Name</label>
                <input type="text" v-model="settings.databaseSetting.db" id="dbName" class="form-control" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label for="dbUser" class="form-label">User</label>
                <input type="text" v-model="settings.databaseSetting.user" id="dbUser" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="dbPassword" class="form-label">Password</label>
                <input type="password" v-model="settings.databaseSetting.password" id="dbPassword" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <!-- SNMP Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">SNMP Settings</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="snmpGroup" class="form-label">Group</label>
                <input type="text" v-model="settings.snmpSetting.group" id="snmpGroup" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="snmpInterval" class="form-label">Interval</label>
                <input type="number" v-model="settings.snmpSetting.interval" id="snmpInterval" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <!-- BGP Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">BGP Settings</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label for="bgpHost" class="form-label">Host</label>
                <input type="text" v-model="settings.bgpSetting.host" id="bgpHost" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="bgpPort" class="form-label">Port</label>
                <input type="number" v-model="settings.bgpSetting.port" id="bgpPort" class="form-control" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6">
                <label for="invalidationInterval" class="form-label">Invalidation Interval</label>
                <input type="number" v-model="settings.bgpSetting.invalidationInterval" id="invalidationInterval" class="form-control" />
              </div>
              <div class="col-md-6">
                <label for="nextHopCheckInterval" class="form-label">Next Hop Check Interval</label>
                <input type="number" v-model="settings.bgpSetting.nextHopCheckInterval" id="nextHopCheckInterval" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <!-- MTR Settings Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">MTR Settings</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <label for="mtrCycle" class="form-label">Cycle</label>
                <input type="number" v-model="settings.mtrSetting.cycle" id="mtrCycle" class="form-control" />
              </div>
              <div class="col-md-4">
                <label for="mtrMaxHops" class="form-label">Max Hops</label>
                <input type="number" v-model="settings.mtrSetting.maxHops" id="mtrMaxHops" class="form-control" />
              </div>
              <div class="col-md-4">
                <label for="mtrInterval" class="form-label">Interval</label>
                <input type="number" v-model="settings.mtrSetting.interval" id="mtrInterval" class="form-control" />
              </div>
            </div>
          </div>
        </div>

        <!-- Interface Configs Section -->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="card-title">Interface Configurations</h5>
          </div>
          <div class="card-body">
            <div v-for="(iface, index) in settings.interfaceConfigs" :key="index" class="mb-3">
              <h6>Interface {{ iface.name }}</h6>
              <div class="row">
                <div class="col-md-6">
                  <label for="ip" class="form-label">IP</label>
                  <input type="text" v-model="iface.ip" class="form-control" />
                </div>
                <div class="col-md-6">
                  <label for="maxUsage" class="form-label">Max Usage</label>
                  <input type="number" v-model="iface.max_usage" class="form-control" />
                </div>
              </div>
              <label for="nextHop" class="form-label mt-3">Next Hop</label>
              <multiselect v-model="iface.next_hop" :options="[]" :taggable="true" :multiple="true" placeholder="Add Next Hops" />
              <label for="ports" class="form-label mt-3">Ports</label>
              <multiselect v-model="iface.ports" :options="[]" :taggable="true" :multiple="true" placeholder="Add Ports" />
            </div>
          </div>
        </div>

        <!-- Save Button -->
        <button type="submit" class="btn btn-primary m-2">Save Settings</button>
      </form>
    </div>
    <div v-else class="text-center">
      <p>Loading settings...</p>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { useToast } from "vue-toastification";
import { axiosRequest } from "@/utils/axios";

const toast = useToast();
let interfaceOptions = [];

export default {
  components: { Multiselect },
  data() {
    return {
      loaded: false,
      options: [],
      settings: {
        mainIface: "",
        debugMode: false,
        databaseSetting: {
          host: "",
          db: "",
          user: "",
          password: "",
        },
        snmpSetting: {
          group: "",
          interval: 0,
        },
        policySetting: {
          next_ips: [],
          sources: [],
          dscp_limit: 0,
        },
        bgpSetting: {
          host: "",
          port: 0,
          invalidationInterval: 0,
          nextHopCheckInterval: 0,
        },
        mtrSetting: {
          cycle: 0,
          maxHops: 0,
          interval: 0,
        },
        interfaceConfigs: [],
      },
    };
  },
  async mounted() {
    await this.fetchInterfaces();
    await this.fetchSettings();
  },
  methods: {
    async fetchSettings() {
      try {
        const response = await axiosRequest("get", "/settings");
        this.settings = response.data;
        this.loaded = true;
      } catch (error) {
        toast.error("Error fetching settings");
        console.error(error);
      }
    },
    async fetchInterfaces() {
      try {
        const response = await axiosRequest("get", "/interface");
        interfaceOptions = response.data;
        this.options = interfaceOptions;
      } catch (error) {
          toast.error("Error fetching interfaces.");
          console.error(error);
      }
    },
    async saveSettings() {
      try {
        await axiosRequest("put", "/settings", this.settings);
        toast.success("Settings saved successfully");
      } catch (error) {
        toast.error("Error saving settings");
        console.error(error);
      }
    },
  },
};
</script>

<style>
.mb-3 {
  margin-bottom: 1.5rem !important;
}
</style>
