<template>
    <div class="w-100 mt-5 px-3">
      <h3 class="mb-4">Routers</h3>
      <div class="table-responsive">
        <table class="table table-striped table-hover align-middle">
          <thead class="table-dark">
            <tr>
              <th>ID</th>
              <th>Prefix</th>
              <th>Prefix Length</th>
              <th>Next Hop</th>
              <th>Min RTT</th>
              <th>Avg RTT</th>
              <th>Min Loss</th>
              <th>Avg Loss</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="route in routes" :key="route.id">
              <td>{{ route.id }}</td>
              <td>{{ route.prefix }}</td>
              <td>{{ route.prefix_length }}</td>
              <td>{{ route.next_hop }}</td>
              <td>{{ route.min_rtt }}</td>
              <td>{{ route.avg_rtt }}</td>
              <td>{{ route.min_loss }}</td>
              <td>{{ route.avg_loss }}</td>
              <td>{{ formatDate(route.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import { axiosRequest } from "@/utils/axios";
  import { useToast } from "vue-toastification";
  
  const toast = useToast();
  
  export default {
    data() {
      return {
        routes: [],
      };
    },
    mounted() {
      this.fetchRoutes();
    },
    methods: {
      async fetchRoutes() {
        try {
          const response = await axiosRequest("get", "routes");
          this.routes = response.data;
        } catch (error) {
          console.error("Error fetching routes:", error);
          toast.error("Failed to fetch routes.");
        }
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
      },
    },
  };
  </script>
  
  <style>
  .table {
    width: 100%;
    margin-top: 20px;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  </style>
  