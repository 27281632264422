
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/LoginPage.vue';
import SettingsPage from './components/SettingsPage.vue';
import GenerateScriptPage from './components/GenerateScriptPage.vue';
import InterfacesPage from './components/InterfacesPage.vue';
import RoutersPage from './components/RoutersPage.vue';

const routes = [
  { path: '/', name: 'Login', component: LoginPage },
  { path: '/interfaces', name: 'Interfaces', component: InterfacesPage },
  { path: '/routers', name: 'Routers', component: RoutersPage },
  { path: '/generate-script', name: 'Generate Script', component: GenerateScriptPage },
  { path: '/settings', name: 'Settings', component: SettingsPage },
  { path: '/logout', name: 'Logout', redirect: () => {
    localStorage.removeItem('user');
    return '/';
  }},
  { path: '/:pathMatch(.*)*', redirect: '/packets' } // Redirect unknown paths to dashboard
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
